:root {
    --brand: #d1d2f9;
    --accent-dark: #3e2c41;
    --accent-light: #6e85b2;
    --shades-dark: #261c2c;
    --shades-light: #5c527f;
    --highlight: #ffd765;
    --backg-c2: #edfbff;
}

/* General Styling */
* {
    margin: 0;
    padding: 0;
    color: var(--brand);
    font-family: "Quicksand";
}
body,
html {
    font-size: 100%;
    min-height: 100%;
    scroll-behavior: smooth;
    overflow: hidden;
    overflow-y: auto;
}
.app {
    min-height: 100%;
    height: 100%;
}
html {
    cursor: none;
    height: 100%;
}
body {
    background-color: var(--shades-light);
    -ms-overflow-style: none;
    scrollbar-width: none;
    min-height: 100%;
}
a {
    text-decoration: none;
    color: inherit;
    cursor: none;
}
nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    z-index: 1;
    background-color: var(--shades-light);
}
.icon {
    margin: 3%;
}
button {
    padding: 4% 0;
    background: transparent;
    border-radius: 5px;
    border: none;
}

html::-webkit-scrollbar,
body::-webkit-scrollbar {
    background: transparent;
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-thumb {
    background-color: #d1d1f9ab;
    border-radius: 18px;
}

::-webkit-scrollbar-track {
    position: absolute;
    background: transparent;
    width: 1rem;
}

/* Responsive Design CSS */

/* Media Queries: Tablet Landscape */
@media screen and (max-width: 1060px) {
    #primary {
        width: 67%;
    }
    #secondary {
        width: 30%;
        margin-left: 3%;
    }
}

/* Media Queries: Tablet Portrait */
@media screen and (max-width: 768px) {
    #primary {
        width: 100%;
    }
    #secondary {
        width: 100%;
        margin: 0;
        border: none;
    }
}
/* Responsive Typography */
@media (min-width: 640px) {
    body {
        font-size: 1rem !important;
    }
}
@media (min-width: 960px) {
    body {
        font-size: 1.2rem !important;
    }
}
@media (min-width: 1100px) {
    body {
        font-size: 1.5rem !important;
    }
}

/* Responsive Cursors */
@media (max-width: 767px) {
    .cursor {
        cursor: pointer;
        display: none;
    }
}

/* Responsive Button */
@media (max-width: 767px) {
    .button:before {
        width: 100%;
        border-radius: 18px;
    }
}

/* Responsive Navigation Menu */
@media screen and (max-width: 500px) {
    .nav-list {
        float: none;
        display: block;
        text-align: left;
    }
}

/* Responsive Grids */
@media (max-width: 800px) {
    /* Content */
    .content {
        padding: 40% 0 30% 10% !important;
    }
    .name {
        margin-top: 20% !important;
        line-height: 140% !important;
    }
    .details {
        margin-top: 10% !important;
    }
    .socials {
        margin-top: 2% !important;
    }
    .head {
        margin: 0 !important;
        padding: 10% 0 0;
    }
    /* Find Me Questions */
    .find-ques {
        grid-auto-flow: unset !important;
        grid-template-columns: unset !important;
    }
    .item-left,
    .item-right {
        width: 100% !important;
    }

    .item-right {
        display: flex;
        gap: 1em;
    }

    /* Work Card */
    .work-card {
        flex-direction: column !important;
    }

    /* Back Design */
    .back-svg {
        display: none;
    }
    .frame {
        background-image: none !important;
    }
}

/* ---------------------- */

/* Cursor code */
.cursor {
    position: fixed;
    top: 300px;
    left: 320px;
    width: 30px;
    height: 30px;
    background-color: #ffd765;
    border-radius: 50%;
    margin-top: -6px;
    margin-left: -5px;
    filter: contrast(0.8) blur(1px);
    mix-blend-mode: luminosity;
    z-index: 9999;
    pointer-events: none;
}

.frame {
    text-align: center;
    height: 100vh;
    background-image: url("../assets/bg-swirl.svg");
    background-repeat: no-repeat;
    background-size: 80%;
    background-position: center;
}

/* Background elements */

.back-svg {
    overflow: hidden;
    position: absolute;
    z-index: -999;
}

.line-1 {
    top: 140px;
    left: 880px;
    animation: moveLine1 10s infinite;
    -webkit-animation: moveLine1 10s infinite;
}
/* Animated Line1 Code */
@keyframes moveLine1 {
    0% {
        transform: translate3d(0, 0, 0);
    }
    50% {
        transform: translate3d(0, 80%, 0);
    }
    100% {
        transform: translate3d(0, 0, 0);
    }
}

.line-2 {
    left: 935px;
    top: 340px;
    animation: moveLine2 10s infinite;
    -webkit-animation: moveLine2 10s infinite;
}
/* Animated Line2 Code */
@keyframes moveLine2 {
    0% {
        transform: translate3d(0, 0, 0);
    }
    50% {
        transform: translate3d(0, -80%, 0);
    }
    100% {
        transform: translate3d(0, 0, 0);
    }
}

.circle {
    top: 340px;
    left: 895px;
}
.bg-swirl {
    width: 100%;
    height: 100%;
}

.swirl {
    left: 70.2px;
    top: 0px;
}

.scroll-left {
    overflow: unset;
    left: -2%;
    bottom: 3%;
}
.scroll-right {
    overflow: unset;
    bottom: 3%;
    right: 0;
    animation: bounce 3s infinite;
}

@keyframes bounce {
    from {
        transform: translate3d(0, 0, 0);
    }
    to {
        transform: translate3d(0, 20px, 0);
    }
}

.scroll-text {
    font-family: "Satisfy";
    font-style: normal;
    font-weight: lighter;
    font-size: 25px;
    line-height: 36px;
    margin-bottom: 50px;
    -webkit-transform: rotate(-90deg);
    color: rgba(255, 215, 101, 0.5);
    transform: rotate(-90deg);
}

/* Navigation Panel code */

.nav-bar {
    box-shadow: 0px 1px 4px 4px rgb(74 66 102);
    z-index: 2;
}

.nav-list {
    width: 100vw;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
}

.nav-list li {
    color: #ffd66580;
    margin: 5px 15px;
    padding: 10px;
    font-family: Quicksand;
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    line-height: 37px;
    /* identical to box height */

    letter-spacing: 0.15em;
    text-transform: uppercase;
    text-decoration: none;
}

.nav-list li a {
    display: inline-block;
    position: relative;
}

.nav-list li a::after,
.nav-list li::after {
    content: "";
    display: block;
    left: 0;
    bottom: 0;
    transform: scaleX(0);
    height: 3px;
    background: #ffd66580;
    transform-origin: bottom right;
    transition: transform 0.7s ease-in-out;
    width: 100%;
}
@media (hover: hover) {
    .nav-list li a:hover::after {
        font-size: 32px;
        transform: scaleX(1);
        transform-origin: bottom left;
    }
}
.nav-list li > .active {
    color: var(--highlight);
    font-size: 32px;
    font-weight: 700;
}
.nav-list li > .active::after {
    content: "";
    display: block;
    bottom: 0;
    right: 0;
    position: relative;
    height: 4px;
    background: var(--highlight);
    transform: scaleX(1);
    transition: width 0.7s ease-in-out;
    width: 50%;
}
@media (hover: hover) {
    .nav-list li > .active:hover::after {
        width: 100%;
    }
}

.resume {
    float: right;
}

.i-download {
    margin: 10px 0 0 5px;
}

/* The sticky class is added with JS when reached its scroll position */
.sticky {
    position: fixed;
    top: 0;
    width: 100%;
}

/* --------------------- */

/* Content */
.content {
    padding: 15% 0 10% 10%;
    position: relative;
    max-width: 45rem;
    text-align: left;
}
.greet {
    font-family: "Satisfy", cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 400%;
    line-height: 120%;
}
.name {
    margin-top: 15%;
    font-family: Share Tech;
    font-style: normal;
    font-weight: normal;
    font-size: 300%;
    line-height: 110%;
    letter-spacing: 0.2em;
}

.p-name {
    font-family: Share Tech;
    position: relative;
    display: inline-block;
    background: linear-gradient(
        to bottom,
        #5c527f 0%,
        #5c527f 50%,
        #5c527f 100%
    );
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    background-repeat: no-repeat;
    transition: background 0.8s ease-in-out;
    white-space: nowrap;
}
.span-name {
    position: relative;
}
.span-name::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    background: #ffd765;
    bottom: 0;
    width: 95%;
    transition: all 0.8s ease-in-out;
}
@media (hover: hover) {
    .p-name:hover {
        background: linear-gradient(
            to bottom,
            #ffd765,
            #ffd765 50%,
            #ffd765 100%
        );
        color: transparent;
        background-clip: text;
    }
    .span-name:hover::before {
        height: 10px;
        transform: translateY(15px);
    }
}
.details {
    margin-top: 5%;
    font-family: Share Tech;
    font-style: normal;
    font-weight: normal;
    font-size: 150%;
    line-height: 160%;
    letter-spacing: 0.05em;
}
.socials {
    position: absolute;
    top: 1%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.socials > .icon {
    margin: 0 1.5%;
}

.head {
    padding: 8% 0 0;
    font-family: Share Tech;
    font-style: normal;
    font-weight: normal;
    font-size: 45px;
    line-height: 51px;
    text-align: center;
    letter-spacing: 0.05em;
    color: var(--accent-light);
}
/* ------- */

/* Work Page CSS */

.work-container {
    background-color: var(--backg-c2);
}

/* Work Card */
.work-cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
    margin: 0 1vw;
    padding: 3% 5%;
    list-style-type: none;
}

.work-card {
    position: relative;
    display: block;
    height: 100%;
    border-radius: calc(30 * 1px);
    border: solid 6px var(--accent-dark);
    overflow: hidden;
    text-decoration: none;
}

@media (hover: hover) {
    .work-card:hover {
        cursor: pointer;
    }
}
.card-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    border-radius: calc(30 * 1px) 0 0 0;
    background-color: var(--shades-dark);
    transform: translateY(80%);
    transition: 0.6s ease-in-out;
}
@media (hover: hover) {
    .work-card:hover .card-overlay {
        transform: translateY(0);
    }
}
/* Work Image */
.card-img {
    width: 100%;
    height: auto;
}

/* Work Description */
.card-header {
    position: relative;
    display: flex;
    align-items: center;
    gap: 2em;
    background-color: var(--shades-dark);
    opacity: 1;
    transition: 0.6s ease-in-out;
}
@media (hover: hover) {
    .work-card:hover .card-header {
        opacity: 1;
        transform: translateY(0);
        border-radius: calc(30 * 1px) 0 0 0;
    }
}
.card-arc {
    width: 80px;
    height: 80px;
    position: absolute;
    bottom: 100%;
    right: 0;
    z-index: 1;
    transform: translateY(100%);
    transition: 0.2s ease-in-out;
}
@media (hover: hover) {
    .work-card:hover .card-arc {
        transform: translateY(0);
    }
}

.card-arc path {
    fill: var(--shades-dark);
}

.card-description {
    padding: 0 2em 0;
    margin: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}

@media (max-width: 800px) {
    .card-header,
    .work-title {
        transition: none !important;
        transform: none !important;
    }
    .card-header {
        opacity: 1;
        border-radius: 0 !important;
    }
    .work-cards {
        grid-template-columns: unset;
        grid-template-rows: repeat(auto-fit, minmax(100px, 1fr));
        grid-gap: 4rem;
    }
    .work-cards:first-child {
        margin: 4rem 0;
    }
    .card-arc {
        display: none;
    }
    .work-title {
        font-size: 30px !important;
    }

    .card-overlay {
        border-radius: 0 !important;
        position: relative;
        transform: none !important;
        transition: none !important;
        background: none !important;
    }
    .card-description {
        display: inline;
        overflow: visible;
    }
}

.work-desc {
    width: 40%;
    max-width: 45%;
    margin: 0 0 0 3%;
}
.work-title {
    font-family: Satisfy;
    font-size: 30px;
    line-height: 58px;
    letter-spacing: 0.1em;
    color: var(--brand);
    margin: 0.3em 0 0 1.3em;
    transform: translateY(-30%);
    transition: 0.4s ease-in;
}

@media (hover: hover) {
    .work-card:hover .work-title {
        font-size: 40px;
        transform: translateY(0);
    }
}

.work-details {
    font-family: Quicksand;
    font-style: italic;
    font-weight: 500;
    font-size: 26px;
    line-height: 37px;
    letter-spacing: 0.045em;
    color: var(--accent-light);
}
/* Source Button */
.button-desc {
    font-family: Share Tech;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 34px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #ffd765;
}
.i-git {
    margin: 0 0 0 6px;
    vertical-align: bottom;
}
/* --------- */
/* ------------- */

/* Find Me Page CSS */
.find-container {
    position: relative;
    z-index: -999;
    background-color: var(--backg-c2);
    padding: 0;
    width: 100%;
    height: 100%;
}

.find-details {
    padding: 5% 0 0 10%;
    color: var(--accent-dark);
}

.find-ques-container {
    margin: 3% 10% 0 10%;
}

.find-ques {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 1fr auto;
    row-gap: 1.2rem;
}
.find-ques > li {
    padding: 1.5%;
}

.item-left {
    width: 55%;
    font-family: Share Tech;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 34px;
    align-items: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: var(--accent-light);
}
.item-right {
    width: 35%;
    font-family: Share Tech;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 27px;
    align-items: flex-start;
    color: var(--shades-dark);
}
.item-right > i {
    margin: 0;
}

.i-find {
    position: absolute;
    z-index: -1;
    opacity: 0.8;
    margin: -0.456% -1.6%;
}
/* ---------------- */

/* Footer CSS */
.credit {
    background-color: var(--backg-c2);
    width: 100%;
    padding: 5% 0 0;
    text-align: right;
}
.foot {
    font-family: Satisfy;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 35px;
    color: rgba(62, 44, 65, 0.4);
}
.foot-name {
    font-family: Share Tech;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 27px;
    text-decoration-line: underline;
    color: rgba(110, 133, 178, 0.6);
}
/* ---------- */
