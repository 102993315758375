.button {
    display: flex;
    justify-content: center;
    gap: 1rem;

    font-family: Share Tech;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 34px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #ffd765;
}
